import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ModalCreateOrderScreen from 'screens/Order/ModalCreateOrderScreen';
import ModalConfirmCreateOrderScreen from 'screens/Order/ModalConfirmCreateOrderScreen';
import OrderCreatedMessageScreen from 'screens/Order/OrderCreatedMessageScreen';
import { notifType, notify } from 'utils/notifSender';
import isNumeric from 'validator/lib/isNumeric';
import { closeCreateOrderModal } from 'redux/reducers/order';
import { connect } from 'react-redux';
import { getProductTypes } from 'api/product-type';
import { getProducts } from 'api/product';
import { createOrder } from 'api/order';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { priceValidator, secondaryClientValidator, volumeValidator } from 'utils/helpers';
import { getBrokerages } from 'api/brokerage';
import { NOTIFICATION_TYPE, PUBLIC_EVENTS, ROUTES, USER_ROLES } from 'config/constants';
import { getAllClients } from 'api/clients';
import ModalLimitError from 'containers/App/components/Modal/ModalLimitError';
import { useHistory } from 'react-router';
import EventEmitter from 'utils/EventEmitter';
import { getCounterparties, getCounterpartiesOf } from 'api/counterparty';
import usePageDetails from 'hooks/usePageDetails';

ModalCreateOrderContainer.propTypes = {
    handleClose: PropTypes.func.isRequired,
    apiCaller: PropTypes.func.isRequired,
    productTypeId: PropTypes.any,
    productId: PropTypes.any,
    isVisible: PropTypes.bool,
    roleType: PropTypes.number,
    profile: PropTypes.object.isRequired,
    isBlock: PropTypes.bool.isRequired
};

function ModalCreateOrderContainer({
    productTypeId,
    productId,
    isVisible,
    handleClose,
    apiCaller,
    roleType,
    profile,
    isBlock
}) {
    const page = usePageDetails(apiCaller);
    // initialOrderToCreate won't change but for optimization purpose put in the state and usememo
    const [initialOrderToCreate, setInitialOrderToCreate] = useState(null);

    const [dataPrepared, setDataPrepared] = useState(false);
    const [productTypes, setProductTypes] = useState([]);
    const [shouldShowModalError, setShouldShowModalError] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState('');
    const [modalErrorLimit, setModalErrorLimit] = useState('');
    const [products, setProducts] = useState([]);

    const [orderToCreate, setOrderToCreate] = useState({
        product_type_id: { value: '', isValid: false, isDirty: false, validator: [isNumeric] },
        product_id: { value: '', isValid: false, isDirty: false, validator: [isNumeric] },
        price: { value: '', isValid: false, isDirty: false, validator: [priceValidator] },
        volume: { value: '', isValid: false, isDirty: false, validator: [volumeValidator] },
        is_firm: { value: !!isBlock, isValid: true, isDirty: false },
        is_visible: { value: true, isValid: true, isDirty: false },
        brokerages: { value: [], isValid: true, isDirty: false },
        note: { value: '', isValid: true },
        type: 0,
        default_price: '',
        currency: '',
        units: '',
        name: '',
        default_volume: '',
        price_increment: '1',
        volume_increment: '1',
        company_id: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false, validator: [isNumeric] },
        trader_id: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false, validator: [isNumeric] },
        company_name: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false },
        trader_name: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false },
        best_buy_price: null,
        best_sell_price: null,

        counterparty_company_id: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false, validator: [isNumeric] },
        counterparty_trader_id: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false, validator: [isNumeric] },
        counterparty_company_name: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false },
        counterparty_trader_name: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false },

        secondary_company_id: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false, validator: [secondaryClientValidator] },
        secondary_trader_id: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false, validator: [isNumeric] },
        secondary_company_name: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false },
        secondary_trader_name: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false }
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [isCounterpartyLoading, setIsCounterpartyLoading] = useState(false);

    const [shouldConfirmShown, setShouldConfirmShown] = useState(false);
    const [shouldSuccessShown, setShouldSuccessShown] = useState(false);
    const [brokeragesList, setBrokeragesList] = useState([]);
    const [orderSummery, setOrderSummery] = useState({});

    // for broker:
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [secondaryCompanies, setSecondaryCompanies] = useState([]);
    const [selectedSecondaryCompany, setSelectedSecondaryCompany] = useState(null);

    // for trader (block trader only)
    const [selectedCounterparty, setSelectedCounterparty] = useState(null);
    const [counterparties, setCounterparties] = useState([]);

    const [submitResult, setSubmitResult] = useState({});
    const [isGtc, setIsGtc] = useState(false);

    //
    const history = useHistory();

    useMemo(() => {
        const initialOrderToCreate = {
            product_type_id: { value: '', isValid: false, isDirty: false, validator: [isNumeric] },
            product_id: { value: '', isValid: false, isDirty: false, validator: [isNumeric] },
            price: { value: '', isValid: false, isDirty: false, validator: [priceValidator] },
            volume: { value: '', isValid: false, isDirty: false, validator: [volumeValidator] },
            is_firm: { value: !!isBlock, isValid: true, isDirty: false },
            is_visible: { value: true, isValid: true, isDirty: false },
            brokerages: { value: [], isValid: true, isDirty: false },
            note: { value: '', isValid: true },
            type: 0,
            default_price: '',
            currency: '',
            units: '',
            name: '',
            default_volume: '',
            price_increment: '1',
            volume_increment: '1',
            company_id: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false, validator: [isNumeric] },
            trader_id: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false, validator: [isNumeric] },
            company_name: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false },
            trader_name: { value: '', isValid: roleType === USER_ROLES.TRADER, isDirty: false },
            best_buy_price: null,
            best_sell_price: null,

            counterparty_company_id: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false, validator: [isNumeric] },
            counterparty_trader_id: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false, validator: [isNumeric] },
            counterparty_company_name: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false },
            counterparty_trader_name: { value: '', isValid: !(roleType === USER_ROLES.TRADER && isBlock), isDirty: false },

            secondary_company_id: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false, validator: [secondaryClientValidator] },
            secondary_trader_id: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false, validator: [isNumeric] },
            secondary_company_name: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false },
            secondary_trader_name: { value: '', isValid: roleType === USER_ROLES.TRADER || !isBlock, isDirty: false }
        };

        setOrderToCreate(initialOrderToCreate);
        setInitialOrderToCreate(initialOrderToCreate);
    }, [isBlock, roleType]);

    const handleBuyClick = () => {
        if (roleType === USER_ROLES.TRADER) {
            if (!orderToCreate.brokerages.value.length) {
                setOrderToCreate(prevState => ({
                    ...prevState,
                    brokerages: {
                        ...prevState.brokerages,
                        isValid: false
                    }
                }));

                return;
            }

            if (handleExtraValidationsForTrader()) {
                return;
            }
        }

        if (roleType === USER_ROLES.BROKERAGE) {
            if (handleExtraValidationsForBrokerage()) {
                return;
            }
        }

        setOrderToCreate(prevState => ({
            ...prevState,
            type: 1
        }));

        setShouldConfirmShown(true);
    };

    const handleSellClick = () => {
        if (roleType === USER_ROLES.TRADER) {
            if (!orderToCreate.brokerages.value.length) {
                setOrderToCreate(prevState => ({
                    ...prevState,
                    brokerages: {
                        ...prevState.brokerages,
                        isValid: false
                    }
                }));

                return;
            }

            if (handleExtraValidationsForTrader()) {
                return;
            }
        }

        if (roleType === USER_ROLES.BROKERAGE) {
            if (handleExtraValidationsForBrokerage()) {
                return;
            }
        }

        setOrderToCreate(prevState => ({
            ...prevState,
            type: 2
        }));

        setShouldConfirmShown(true);
    };

    const handleExtraValidationsForTrader = () => {
        const finalVolume = orderToCreate.volume.value === '' && !orderToCreate.volume.isDirty ? orderToCreate.default_volume : orderToCreate.volume.value;

        if (finalVolume) {
            const totalVolume = Number(finalVolume);

            const startVolume = 0; // Number(orderToCreate.default_volume);
            const addedVolume = totalVolume - startVolume;

            if (orderToCreate.volume_increment && addedVolume % Number(orderToCreate.volume_increment) !== 0) {
                notify('Order volume is not matched with increment value', notifType.DANGER);

                return true;
            }
        }

        return false;
    };

    const handleExtraValidationsForBrokerage = () => {
        if (!orderToCreate.company_id.value) {
            notify('Please select the primary client company!', notifType.DANGER);

            return true;
        }

        if (!orderToCreate.trader_id.value) {
            notify('Please select the primary trader!', notifType.DANGER);

            return true;
        }

        if (isBlock && !orderToCreate.secondary_company_id.value) {
            notify('Please select the secondary client company!', notifType.DANGER);

            return true;
        }

        if (isBlock && !orderToCreate.secondary_trader_id.value) {
            notify('Please select the secondary trader!', notifType.DANGER);

            return true;
        }

        return false;
    };

    const handleSubmit = async () => {
        setIsProcessing(true);

        try {
            const data = {
                product_id: orderToCreate.product_id.value,
                is_firm: orderToCreate.is_firm.value,
                price: orderToCreate.price.value === '' && !orderToCreate.price.isDirty ? orderToCreate.default_price : orderToCreate.price.value,
                volume: orderToCreate.volume.value === '' && !orderToCreate.volume.isDirty ? orderToCreate.default_volume : orderToCreate.volume.value,
                note: orderToCreate.note.value,
                type: orderToCreate.type,
                is_visible: orderToCreate.is_visible.value,
                is_gtc: isGtc
            };

            if (roleType === USER_ROLES.TRADER) {
                data.is_splittable = false;
                data.brokerages = orderToCreate.brokerages.value;

                if (isBlock) {
                    data.is_block_order = true;
                    data.counterparty_id = orderToCreate.counterparty_company_id.value;
                    data.counterparty_trader_id = orderToCreate.counterparty_trader_id.value;
                }
            }

            if (roleType === USER_ROLES.BROKERAGE) {
                data.company_id = orderToCreate.company_id.value;
                data.trader_id = orderToCreate.trader_id.value;

                if (isBlock) {
                    data.is_block_order = true;
                    data.counterparty_id = orderToCreate.secondary_company_id.value;
                    data.counterparty_trader_id = orderToCreate.secondary_trader_id.value;
                }
            }

            setOrderSummery({
                ...data,
                currency: orderToCreate.currency,
                units: orderToCreate.units,
                name: orderToCreate.name
            });

            const submitRes = await apiCaller(createOrder, data);

            setSubmitResult(submitRes.data);
            setIsProcessing(false);
            setOrderToCreate(initialOrderToCreate);
            setShouldSuccessShown(true);
        } catch (error) {
            const response = error.response;

            if (response.status === 403 && response.data.message.length > 0) {
                setShouldShowModalError(true);
                setModalErrorMessage(response.data.message);

                if (response.data.limit.length > 0) {
                    setModalErrorLimit(response.data.limit);
                }
            } else if (response.status === 422) {
                const errs = Object.keys(response.data.errors);
                const respErr = response.data.errors;

                for (const err of errs) {
                    respErr[err].map(msg => notify(msg, notifType.DANGER));
                }

                setIsProcessing(false);
            } else {
                notify('An error occurred!', notifType.DANGER);
            }
        }
    };

    const validateForm = (value, rules, attributes, isDirty) => {
        return rules ? rules.reduce((carry, rule) => rule(value, attributes, isDirty) && carry, true) : true;
    };

    const setDefaultAttributes = useCallback(
        (product, productType) => {
            setOrderToCreate(prevState => ({
                ...prevState,
                price: {
                    value: prevState.price.value ? parseFloat(prevState.price.value).toFixed(2) : prevState.price.value,
                    isDirty: prevState.price.isDirty,
                    isValid: validateForm(prevState.price.value, prevState.price.validator, {
                        ...prevState,
                        default_price: product ? product.default_price.toString() : '',
                        default_volume: product ? product.default_volume.toString() : ''
                    }, prevState.price.isDirty),
                    validator: prevState.price.validator
                },
                volume: {
                    value: prevState.volume.value,
                    isDirty: prevState.volume.isDirty,
                    isValid: validateForm(prevState.volume.value, prevState.volume.validator, {
                        ...prevState,
                        default_price: product ? product.default_price.toString() : '',
                        default_volume: product ? product.default_volume.toString() : ''
                    }, prevState.price.isDirty),
                    validator: prevState.volume.validator
                },
                currency: productType ? productType.currency : '',
                unit: productType ? productType.unit : '',
                units: product ? product.units : '',
                default_price: product && product.default_price ? parseFloat(product.default_price).toFixed(2).toString() : '',
                default_volume: product && product.default_volume ? product.default_volume.toString() : '',
                price_increment: product ? product.min_price_increment.toString() : '1',
                volume_increment: product ? product.volume_increment.toString() : '1',
                name: product && productType ? `${productType.name} ${product.tenor}` : '',
                best_buy_price: product && product.best_buy_order ? product.best_buy_order.price : null,
                best_sell_price: product && product.best_sell_order ? product.best_sell_order.price : null,
                volume_unit: productType ? productType?.volume_unit : ''
            }));
        }, []
    );

    const handleCreateFieldChange = useCallback(async (field, value = '', isDirty = true) => {
        try {
            setOrderToCreate(prevState => {
                const nextValue = typeof value === 'function' ? value(prevState) : value;
                const nextIsDirty = typeof isDirty === 'function' ? isDirty(prevState) : isDirty;

                return {
                    ...prevState,
                    is_splittable: false,
                    [field]: {
                        value: nextValue,
                        isDirty: nextIsDirty,
                        isValid: validateForm(
                            typeof value === 'function' ? value(prevState) : value,
                            prevState[field].validator, prevState, nextIsDirty),
                        validator: prevState[field].validator
                    }
                };
            });

            if (field === 'product_type_id') {
                setIsProductLoading(true);
                setBrokeragesList([]);

                const [products, brokerages, companies, counterparties] = await Promise.all([
                    await apiCaller(getProducts, {
                        product_type_id: value,
                        tradable_only: true
                    }),
                    roleType === USER_ROLES.TRADER
                        ? apiCaller(getBrokerages, { product_type_id: value }) : { data: { data: [] } },
                    (roleType === USER_ROLES.BROKERAGE && value)
                        ? apiCaller(getAllClients, { product_type_id: value }) : { data: { data: [] } },
                    (roleType === USER_ROLES.TRADER && isBlock && value)
                        ? apiCaller(getCounterparties, { product_type_id: value, with_traders: true }) : { data: { data: [] } }
                ]);

                setProducts(products.data.data);

                setBrokeragesList(brokerages.data.data.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                }));

                setCompanies(companies.data.data.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                }));

                setCounterparties(counterparties.data.data);

                await handleCreateFieldChange('product_id', '');

                // select Rmd as brokerage
                if (brokerages.data.data.length === 1) {
                    handleCreateFieldChange('brokerages', [brokerages.data.data[0].id]);
                } else {
                    await handleCreateFieldChange('brokerages', []);
                }

                if (roleType === USER_ROLES.BROKERAGE) {
                    setSelectedCompany(null);
                    await handleCreateFieldChange('company_id', '');
                    await handleCreateFieldChange('trader_id', '');
                    await handleCreateFieldChange('company_name', '');
                    await handleCreateFieldChange('trader_name', '');

                    if (isBlock) {
                        setSelectedSecondaryCompany(null);
                        setSecondaryCompanies([]);

                        await handleCreateFieldChange('secondary_company_id', '');
                        await handleCreateFieldChange('secondary_trader_id', '');
                        await handleCreateFieldChange('secondary_company_name', '');
                        await handleCreateFieldChange('secondary_trader_name', '');
                    }
                }

                const selectedProductType = productTypes.find(
                    productType => productType.id === Number(value)
                );

                setOrderToCreate(prevOrder => ({
                    ...prevOrder,
                    currency: selectedProductType.currency,
                    unit: selectedProductType.unit,
                    volume_unit: selectedProductType.volume_unit
                }));
                setIsProductLoading(false);
            }

            // for normal trade brokerage side only
            if (field === 'company_id') {
                let companyName = '';

                if (value) {
                    const company = companies.find(c => c.id === Number(value));

                    setSelectedCompany(company);
                    companyName = company.name;

                    if (isBlock) {
                        setIsCounterpartyLoading(true);

                        const counterparties = await apiCaller(getCounterpartiesOf, company.id, { product_type_id: orderToCreate.product_type_id.value });

                        setSecondaryCompanies(counterparties.data.data.filter(company => {
                            return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                        }));

                        setIsCounterpartyLoading(false);
                    }
                }

                await handleCreateFieldChange('company_name', companyName);
                await handleCreateFieldChange('trader_id', '');

                if (isBlock) {
                    await handleCreateFieldChange('secondary_company_id', '');
                    await handleCreateFieldChange('secondary_trader_id', '');
                }
            }

            // for normal trade brokerage side only
            if (field === 'trader_id') {
                const traderName = selectedCompany && value ? selectedCompany.traders.find(t => t.id === Number(value)).name : '';

                await handleCreateFieldChange('trader_name', traderName);
            }

            // for block trade brokerage side only
            if (field === 'secondary_company_id') {
                let companyName = '';

                if (value) {
                    const company = secondaryCompanies.find(c => c.id === Number(value));

                    setSelectedSecondaryCompany(company);
                    companyName = company.name;
                }

                await handleCreateFieldChange('secondary_company_name', companyName);
                await handleCreateFieldChange('secondary_trader_id', '');
            }

            // for block trade brokerage side only
            if (field === 'secondary_trader_id') {
                const traderName = selectedSecondaryCompany && value ? selectedSecondaryCompany.traders.find(t => t.id === Number(value)).name : '';

                await handleCreateFieldChange('secondary_trader_name', traderName);
            }

            // for block trade, trader side only
            if (field === 'counterparty_company_id') {
                let companyName = '';

                if (value) {
                    const company = counterparties.find(c => c.id === Number(value));

                    setSelectedCounterparty(company);
                    companyName = company.name;
                }

                await handleCreateFieldChange('counterparty_company_name', companyName);
                await handleCreateFieldChange('counterparty_trader_id', '');
            }

            // for block trade, trader side only
            if (field === 'counterparty_trader_id') {
                const traderName = selectedCounterparty && value ? selectedCounterparty.traders.find(t => t.id === Number(value)).name : '';

                await handleCreateFieldChange('counterparty_trader_name', traderName);
            }

            if (field === 'is_firm') {
                await handleCreateFieldChange(
                    'volume',
                    (prevState) => {
                        return prevState.volume.value;
                    },
                    (prevState) => {
                        return prevState.volume.isDirty;
                    }
                );
            }

            if (field === 'product_id') {
                const selectedProduct = products.find(product => product.id === parseInt(value));
                const selectedProductType = selectedProduct ? productTypes.find(
                    productType => productType.id === selectedProduct.product_type_id
                ) : null;

                setDefaultAttributes(selectedProduct, selectedProductType);
            }
        } catch (error) {
            notify('An error occurred!', notifType.DANGER);
        }
    }, [apiCaller, companies, counterparties, isBlock, orderToCreate.product_type_id.value, productTypes, products, roleType, secondaryCompanies, selectedCompany, selectedCounterparty, selectedSecondaryCompany, setDefaultAttributes]);

    const prepareData = useCallback(
        async (productTypeId, productId) => {
            try {
                setIsProcessing(true);
                setIsProductLoading(true);

                const [productTypes, products, brokerages, companies, counterparties] = await Promise.all([
                    apiCaller(getProductTypes, { tradable_only: true }),
                    productTypeId ? await apiCaller(getProducts, {
                        product_type_id: productTypeId,
                        tradable_only: true
                    }) : { data: { data: [] } },
                    roleType === USER_ROLES.TRADER && productTypeId
                        ? apiCaller(getBrokerages, { product_type_id: productTypeId }) : { data: { data: [] } },
                    (roleType === USER_ROLES.BROKERAGE && productTypeId)
                        ? apiCaller(getAllClients, { product_type_id: productTypeId }) : { data: { data: [] } },
                    (roleType === USER_ROLES.TRADER && isBlock && productTypeId)
                        ? apiCaller(getCounterparties, { product_type_id: productTypeId, with_traders: true }) : { data: { data: [] } }
                ]);

                setProductTypes(productTypes.data.data);
                setProducts(products.data.data);

                setBrokeragesList(brokerages.data.data.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                }));

                setCompanies(companies.data.data.filter(company => {
                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                }));

                setCounterparties(counterparties.data.data);

                setOrderToCreate(prevState => ({
                    ...prevState,
                    product_type_id: {
                        value: productTypeId,
                        isValid: validateForm(
                            productTypeId,
                            prevState.product_type_id.validator,
                            prevState
                        ),
                        validator: prevState.product_type_id.validator
                    },
                    product_id: {
                        value: productId,
                        isValid: validateForm(
                            productId,
                            prevState.product_id.validator,
                            prevState
                        ),
                        validator: prevState.product_id.validator
                    }
                }));

                const selectedProduct = products.data.data.find(product => product.id === parseInt(productId));
                const selectedProductType = selectedProduct ? productTypes.data.data.find(
                    productType => productType.id === selectedProduct.product_type_id)
                    : null;

                setDefaultAttributes(selectedProduct, selectedProductType);
                setIsProcessing(false);
                setDataPrepared(true);
                setIsProductLoading(false);

                // select Rmd as brokerage
                if (brokerages.data.data.length === 1) {
                    handleCreateFieldChange('brokerages', [brokerages.data.data[0].id]);
                }
            } catch (error) {
                notify('An error occurred!', notifType.DANGER);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [apiCaller, isBlock, roleType, setDefaultAttributes]
    );

    useEffect(() => {
        if (isVisible && !dataPrepared) {
            prepareData(productTypeId, productId);
        }
    }, [dataPrepared, isVisible, prepareData, productId, productTypeId]);

    useEffect(() => {
        const onNewNotificationReceived = async(event) => {
            try {
                if (!shouldShowModalError && !shouldSuccessShown && !shouldConfirmShown) {
                    if (event.type === NOTIFICATION_TYPE.BROKERAGE_DEACTIVATED && orderToCreate.product_type_id.value) {
                        if (roleType === USER_ROLES.TRADER) {
                            const removedBrokerageIndex = brokeragesList.findIndex(b => b.id === event.data.brokerage.id);

                            if (removedBrokerageIndex !== -1) {
                            // remove the brokerage from the list and the selected brokerages
                                setBrokeragesList(prevBrokerages => prevBrokerages.filter((_, index) => index !== removedBrokerageIndex));
                                await handleCreateFieldChange('brokerages', orderToCreate.brokerages.value.filter(brokerageId => brokerageId !== event.data.brokerage.id));
                            }
                        } else {
                        // brokerage role
                            const removedCompanyIndex = companies.findIndex(c => c.id === event.data.company.id);

                            if (removedCompanyIndex !== -1) {
                            // remvoe company from the list
                                setCompanies(prevCompanies => prevCompanies.filter((_, index) => index !== removedCompanyIndex));

                                if (selectedCompany && selectedCompany.id === event.data.company.id) {
                                    setSelectedCompany(null);
                                    await handleCreateFieldChange('company_id', '');
                                    await handleCreateFieldChange('trader_id', '');
                                    await handleCreateFieldChange('company_name', '');
                                    await handleCreateFieldChange('trader_name', '');
                                }

                                if (selectedSecondaryCompany && selectedSecondaryCompany.id === event.data.company.id) {
                                    setSelectedSecondaryCompany(null);
                                    await handleCreateFieldChange('secondary_company_id', '');
                                    await handleCreateFieldChange('secondary_trader_id', '');
                                    await handleCreateFieldChange('secondary_company_name', '');
                                    await handleCreateFieldChange('secondary_trader_name', '');
                                }
                            }
                        }
                    }

                    if (event.type === NOTIFICATION_TYPE.BROKERAGE_ACTIVATED && orderToCreate.product_type_id.value) {
                        if (roleType === USER_ROLES.TRADER) {
                            // reloading brokerage list as it should be checked on BE that this brokerage should be shown or not
                            const brokerages = await apiCaller(getBrokerages, { product_type_id: orderToCreate.product_type_id.value });

                            setBrokeragesList(brokerages.data.data.filter(company => {
                                return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                            }));
                        } else {
                            // brokerage role
                            const companies = await apiCaller(getAllClients, { product_type_id: orderToCreate.product_type_id.value });

                            setCompanies(companies.data.data.filter(company => {
                                return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                            }));
                        }
                    }

                    if (event.type === NOTIFICATION_TYPE.COMPANY_PRODUCT_ACTIVATED) {
                        //
                        if (roleType === USER_ROLES.TRADER) {
                            const productTypes = await apiCaller(getProductTypes, { tradable_only: true });

                            setProductTypes(productTypes.data.data);
                        } else {
                            // broker

                            if (Number(orderToCreate.product_type_id.value) === event.data.product_type.id) {
                                const companies = await apiCaller(getAllClients, { product_type_id: orderToCreate.product_type_id.value });

                                setCompanies(companies.data.data.filter(company => {
                                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                                }));
                            }
                        }
                    }

                    if (event.type === NOTIFICATION_TYPE.COMPANY_PRODUCT_DEACTIVATED) {
                        if (roleType === USER_ROLES.TRADER) {
                            setProductTypes(prevProductTypes => prevProductTypes.filter(pt => pt.id !== event.data.product_type.id));

                            if (Number(orderToCreate.product_type_id.value) === event.data.product_type.id) {
                                setOrderToCreate(initialOrderToCreate);
                            }
                        } else {
                            // broker
                            if (Number(orderToCreate.product_type_id.value) === event.data.product_type.id) {
                                setCompanies(prevCompanies => prevCompanies.filter(cmp => cmp.id !== event.data.company.id));

                                if (Number(orderToCreate.company_id) === event.data.company.id) {
                                    await handleCreateFieldChange('company_id', '');
                                    await handleCreateFieldChange('trader_id', '');
                                    await handleCreateFieldChange('company_name', '');
                                    await handleCreateFieldChange('trader_name', '');
                                    setSelectedCompany(null);
                                }

                                if (Number(orderToCreate.secondary_company_id) === event.data.company.id) {
                                    await handleCreateFieldChange('secondary_company_id', '');
                                    await handleCreateFieldChange('secondary_trader_id', '');
                                    await handleCreateFieldChange('secondary_company_name', '');
                                    await handleCreateFieldChange('secondary_trader_name', '');
                                    setSelectedSecondaryCompany(null);
                                }
                            }
                        }
                    }

                    if (event.type === NOTIFICATION_TYPE.CLIENT_DEACTIVATED) {
                        if (roleType === USER_ROLES.BROKERAGE) {
                            //
                            setCompanies(prevCompanies => prevCompanies.filter(cmp => cmp.id !== event.data.company.id));

                            if (Number(orderToCreate.company_id.value) === event.data.company.id) {
                                await handleCreateFieldChange('company_id', '');
                                await handleCreateFieldChange('trader_id', '');
                                await handleCreateFieldChange('company_name', '');
                                await handleCreateFieldChange('trader_name', '');
                                setSelectedCompany(null);
                            }

                            if (Number(orderToCreate.secondary_company_id) === event.data.company.id) {
                                await handleCreateFieldChange('secondary_company_id', '');
                                await handleCreateFieldChange('secondary_trader_id', '');
                                await handleCreateFieldChange('secondary_company_name', '');
                                await handleCreateFieldChange('secondary_trader_name', '');
                                setSelectedSecondaryCompany(null);
                            }
                        } else if (roleType === USER_ROLES.TRADER && orderToCreate.product_type_id.value) {
                            const removedBrokerageIndex = brokeragesList.findIndex(b => b.id === event.data.brokerage.id);

                            if (removedBrokerageIndex !== -1) {
                                // remove the brokerage from the list and the selected brokerages
                                setBrokeragesList(prevBrokerages => prevBrokerages.filter((_, index) => index !== removedBrokerageIndex));
                                await handleCreateFieldChange('brokerages', orderToCreate.brokerages.value.filter(brokerageId => brokerageId !== event.data.brokerage.id));
                            }
                        }
                    }

                    if (event.type === NOTIFICATION_TYPE.CLIENT_ACTIVATED) {
                        //
                        if (roleType === USER_ROLES.BROKERAGE) {
                            //

                            if (Number(orderToCreate.product_type_id.value)) {
                                const companies = await apiCaller(getAllClients, { product_type_id: orderToCreate.product_type_id.value });

                                setCompanies(companies.data.data.filter(company => {
                                    return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                                }));
                            }
                        } else if (roleType === USER_ROLES.TRADER && orderToCreate.product_type_id.value) {
                            // reloading brokerage list as it should be checked on BE that this brokerage should be shown or not
                            const brokerages = await apiCaller(getBrokerages, { product_type_id: orderToCreate.product_type_id.value });

                            setBrokeragesList(brokerages.data.data.filter(company => {
                                return !company.is_primary_broker_product_missing && !company.is_secondary_broker_product_missing;
                            }));
                        }
                    }

                    if (event.type === NOTIFICATION_TYPE.TRADER_UPDATED) {
                        if (roleType === USER_ROLES.TRADER) {
                            const productTypeIds = event.data.product_types.map(pt => pt.id);
                            const currentProductTypeIds = productTypes.map(p => p.id);
                            const removedIds = currentProductTypeIds.filter(ptId => !productTypeIds.includes(ptId));
                            const addedProducts = event.data.product_types.filter(product => !currentProductTypeIds.includes(product.id));

                            if (removedIds.length) {
                                setProductTypes(prevProductTypes => prevProductTypes.filter(pt => productTypeIds.includes(pt.id)));

                                if (Number(orderToCreate.product_type_id.value) > 0 && !productTypeIds.includes(Number(orderToCreate.product_type_id.value))) {
                                    setOrderToCreate(initialOrderToCreate);
                                }
                            }

                            if (addedProducts.length) {
                                const productTypes = await apiCaller(getProductTypes, { tradable_only: true });

                                setProductTypes(productTypes.data.data);
                            }
                        }
                    }
                }
            } catch (error) {
                notify('An error occurred!', notifType.DANGER);
            }
        };

        // adding event listeners on mount here
        const listener = EventEmitter.addListener('NewNotificationReceived', onNewNotificationReceived);

        return () => {
            // cleaning up the listeners here
            listener.remove();
        };
    }, [apiCaller, brokeragesList, companies, handleCreateFieldChange, initialOrderToCreate, orderToCreate, productTypeId, productTypes, productTypes.length, roleType, selectedCompany, selectedSecondaryCompany, shouldConfirmShown, shouldShowModalError, shouldSuccessShown]);

    useEffect(() => {
        // this events are only used to update best buy/sell price properties
        const onProductUpdated = (event) => {
            if (orderToCreate.product_id.value && parseInt(orderToCreate.product_id.value) === event.id) {
                // update best prices
                setOrderToCreate(prev => ({
                    ...prev,
                    best_buy_price: event.best_buy_order ? event.best_buy_order.price : null,
                    best_sell_price: event.best_sell_order ? event.best_sell_order.price : null
                }));
            } else if (products.length) {
                // update products to avoid invalid data for next product selections
                setProducts(prev => prev.map(p => {
                    if (p.id === event.id) {
                        return {
                            ...p,
                            best_buy_order: event.best_buy_order,
                            best_sell_order: event.best_sell_order
                        };
                    }

                    return p;
                }));
            }
        };

        // adding event listeners on mount here
        const productUpdatedListener = EventEmitter.addListener(PUBLIC_EVENTS.PRODUCT_UPDATED, onProductUpdated);

        return () => {
            // cleaning up the listeners here
            productUpdatedListener.remove();
        };
    }, [orderToCreate.product_id.value, products.length]);

    const handleConfirmBack = () => {
        setShouldConfirmShown(false);
    };

    const isFormValid = () => {
        return Object.keys(orderToCreate).reduce((carry, field) => {
            const isValid = (orderToCreate[field] && typeof orderToCreate[field].isValid !== 'undefined')

            // ? orderToCreate[field].isValid

                ? field === 'price' && orderToCreate.price.value !== '' ? orderToCreate.price.value : orderToCreate[field].isValid
                : true;

            return isValid && carry;
        }, true);
    };

    const handleModalClose = () => {
        setDataPrepared(false);
        setOrderToCreate(initialOrderToCreate);
        setProductTypes([]);
        setProducts([]);
        setShouldConfirmShown(false);
        setShouldSuccessShown(false);
        setShouldShowModalError(false);
        handleClose();
    };

    const handleGotoIndications = () => {
        handleClose();
        setDataPrepared(false);
        setOrderToCreate(initialOrderToCreate);
        setProductTypes([]);
        setProducts([]);
        setShouldConfirmShown(false);
        setShouldSuccessShown(false);
        setShouldShowModalError(false);
        history.replace(ROUTES.INDICATIONS);
    };

    return (
        isVisible
            ? !shouldShowModalError
                ? !shouldSuccessShown
                    ? !shouldConfirmShown
                        ? <ModalCreateOrderScreen
                            platformTitle={page.title}
                            isGtc={isGtc}
                            setIsGtc={setIsGtc}
                            isVisible={isVisible}
                            isProcessing={isProcessing}
                            handleClose={handleModalClose}
                            productTypes={productTypes}
                            products={products}
                            isProductLoading={isProductLoading}
                            isCounterpartyLoading={isCounterpartyLoading}
                            handleCreateFieldChange={handleCreateFieldChange}
                            handleBuyClick={handleBuyClick}
                            isFormValid={isFormValid}
                            handleSellClick={handleSellClick}
                            orderToCreate={orderToCreate}
                            roleType={roleType}
                            brokeragesList={brokeragesList}
                            companies={companies}
                            secondaryCompanies={secondaryCompanies}
                            counterparties={counterparties}
                            selectedCompany={selectedCompany}
                            profile={profile}
                            selectedSecondaryCompany={selectedSecondaryCompany}
                            isBlock={isBlock}
                            selectedCounterparty={selectedCounterparty} />
                        : <ModalConfirmCreateOrderScreen
                            isVisible={isVisible}
                            isProcessing={isProcessing}
                            handleClose={handleModalClose}
                            handleBack={handleConfirmBack}
                            handleSubmit={handleSubmit}
                            orderToCreate={orderToCreate}
                            roleType={roleType}
                            isBlock={isBlock}
                            brokeragesList={brokeragesList} />
                    : <OrderCreatedMessageScreen
                        isVisible={isVisible}
                        handleGotoIndications={handleGotoIndications}
                        isProcessing={isProcessing}
                        handleClose={handleModalClose}
                        roleType={roleType}
                        profile={profile}
                        orderSummery={orderSummery}
                        submitResult={submitResult}
                        brokeragesList={brokeragesList} />
                : <ModalLimitError
                    isVisible={isVisible}
                    onClose={handleModalClose}
                    limit={modalErrorLimit}
                    message={modalErrorMessage} />
            : null
    );
}

const mapStateToProps = state => {
    return {
        isVisible: state.order.shouldCreateOrderShown,
        productTypeId: state.order.preSelectedProductTypeId,
        productId: state.order.preSelectedProductId,
        roleType: state.auth.profile.role_type,
        profile: state.auth.profile,
        isBlock: state.order.isBlock
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleClose: () => {
            dispatch(closeCreateOrderModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAsyncCaller(ModalCreateOrderContainer));
